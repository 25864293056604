<template>
    <div class="section-contact" data-scroll="5" id="contact">
        <div class="container">
            <div class="section-contact__office">
                <h2 class="section-contact__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/contact-${$i18n.locale}.png`))" alt="contact"></h2>
                <div class="section-contact__wrapper">
                    <div class="section-contact__content">
                        <div class="section-contact__row row-reverse">
                            <div class="section-contact__col section-contact__image">
                                <img class="lazyload" :data-src="webp(require(`../../../public/img/corporat/product/contact-img01.jpg`))" alt="Business Office">
                            </div>
                            <div class="section-contact__col section-contact__text right">
                                <h3 v-html="$t('contact.office.label')"></h3>
                                <p v-html="$t('contact.office.address1')"></p>
                                <p v-html="$t('contact.office.address2')"></p>
                                <p><a href="tel:+842873080800">{{ $t('contact.label.tel') }}: +84 (0) 287 308 0800</a></p>
                            </div>
                        </div><!--/ .section-contact__row -->

                        <div class="section-contact__row">
                            <div class="section-contact__col image">
                                <img class="lazyload" :data-src="webp(require(`../../../public/img/corporat/product/contact-img02.jpg`))" alt="Factory">
                            </div>
                            <div class="section-contact__col section-contact__text left">
                                <h3>{{ $t('contact.factory.label') }}</h3>
                                <p>{{ $t('contact.factory.address') }}</p>
                                <p><a href="tel:+842513681368">{{ $t('contact.label.tel') }}: +84 (0) 251 368 1368</a></p>
                                <p>Fax: +84 (0) 251 368 1369</p>
                                <p><a href="mailto:kkyvn-customer@koike-ya.com">Email: kkyvn-customer@koike-ya.com</a></p>
                                <p>{{ $t('contact.label.taxNumber') }}: 3603361812</p>
                            </div>
                        </div><!--/ .section-contact__row -->
                    </div><!--/ .section-contact__content -->
                </div><!--/ .section-contact__wrapper -->
            </div>
        </div><!--/ container -->
    </div>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;

    .section-contact {
        padding-top: 53px;
        margin-top: 30px;
        margin-bottom: 40px;
        line-height: 30px;
        font-size: 16px;
        text-align: center;
        background: {
            image: url("../../../public/img/corporat/bg/bg-contact-left-sp.jpg"), url("../../../public/img/corporat/bg/bg-contact-right-sp.jpg");
            repeat: no-repeat;
            position: left 70px, right top;
        }

        @include media(set-min-w, $sm) {
            padding-top: 110px;
            margin-bottom: 80px;
            background: {
                image: url("../../../public/img/corporat/bg/bg-contact.jpg");
                repeat: no-repeat;
                position: center top;
                size: contain;
            }
        }

        a {
            color: #000;
        }

        .container {
            max-width: 1360px;
            padding: 0 15px;
            margin: 0 auto;
        }

        &__title {
            margin-bottom: 37px;
            @include media(set-min-w, $sm) {
                margin-bottom: 56px;
            }

            img {
                max-height: 30px;
                @include media(set-min-w, $sm) {
                    max-height: none;
                }
            }
        }

        &__wrapper {
            padding: 15px;
            background: {
                color: #fff;
                image: url("../../../public/img/corporat/bg/section-contact__wrapperX.jpg"), url("../../../public/img/corporat/bg/section-contact__wrapperX.jpg"), url("../../../public/img/corporat/bg/section-contact__wrapperY.jpg"), url("../../../public/img/corporat/bg/section-contact__wrapperY.jpg");
                repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                position: left top, left bottom, left 12px, right 12px;
                size: auto 15px, auto 15px, 15px auto, 15px auto;
            }
            @include media(set-min-w, $sm) {
                padding: 24px;
                background-size: auto;
                background-position: left top, left bottom, left 24px, right 24px;
            }
        }

        &__row {
            @include media(set-min-w, $sm) {
                @include flex-c-c();
            }

            & + & {
                margin-top: 70px;
            }

            &.row-reverse {
                @include media(set-min-w, $sm) {
                    flex-direction: row-reverse;
                }
            }
        }

        &__col {
            @include media(set-min-w, $sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        &__content {
            padding: 55px 30px 0;
            @include media(set-min-w, $sm) {
                max-width: 990px;
                padding: 60px 15px;
                margin: 0 auto;
            }

            h3 {
                line-height: initial;
                color: #ffaf04;
                font-size: 36px;
                font-weight: 700;
            }
        }

        &__image {
            margin-bottom: 15px;

            img {
                max-width: 100%;
            }
        }

        &__text {
            margin-bottom: 50px;
            font-weight: 600;
            @include media(set-min-w, $sm) {
                margin-bottom: 0;
            }

            &.right {
                @include media(set-min-w, $sm) {
                    padding-right: 45px;
                    text-align: right;
                }
            }

            &.left {
                @include media(set-min-w, $sm) {
                    padding-left: 45px;
                    text-align: left;
                }
            }
        }
    }
    .webp {
        .section-contact {
            background: {
                image: url("../../../public/img/corporat/bg/bg-contact-left-sp.webp"), url("../../../public/img/corporat/bg/bg-contact-right-sp.webp");
            }

            @include media(set-min-w, $sm) {
                background: {
                    image: url("../../../public/img/corporat/bg/bg-contact.webp");
                }
            }

            &__wrapper {
                background: {
                    image: url("../../../public/img/corporat/bg/section-contact__wrapperX.webp"), url("../../../public/img/corporat/bg/section-contact__wrapperX.webp"), url("../../../public/img/corporat/bg/section-contact__wrapperY.webp"), url("../../../public/img/corporat/bg/section-contact__wrapperY.webp");
                }
            }
        }
    }

</style>